@import './brand/fonts.scss';

.obs {
  background: url('./images/bs-sugar-bg.png') top center no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100vw;

  &_modal {
    background: var(--obs-dark-yellow-70);
    display: none;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 999999;

    &[data-active='true'] {
      display: block;
    }

    &__exit {
      background: 0;
      border: 0;
      color: #000;
      font-size: 24px;
      height: 25px;
      position: absolute;
      right: 5px;
      top: 5px;
      text-transform: uppercase;
      width: 25px;
      z-index: 99;

      &::after,
      &::before {
        content: '';
        background: #000;
        position: absolute;
        height: 25px;
        left: 50%;
        position: absolute;
        top: 0%;
        transform: translateX(-50%);
        width: 2px;
        z-index: 9999999;
      }
     
      &::after {
        transform: rotate(45deg)
      }
      
      &::before {
        transform: rotate(-45deg)
      }
    }

    &__form {
      background: #fff;
      box-sizing: border-box;
      height: 550px;
      left: 50%;
      max-width: 650px;
      overflow: hidden;
      padding: 25px 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media (min-width: 1100px) {
        width: 50%;
      }
      
      @media (min-width: 1440px) {
        width: 40%;
      }
    }
  }
  
  @keyframes sugarpowder {
    0% {
      opacity: 0;
      right: -10%;
      top: -10%;
    }
    100% {
      opacity: 1;
      right: 0;
      top: 0;
    }
  }


 @media (min-width: 1024px) {
    @keyframes sugarpowder {
      0% {
        opacity: 0;
        right: -15%;
        top: -30%;
      }
      100% {
        opacity: 1;
        right: 5%;
        top: -20%;
      }
    }
  }
  

  &_sugar__powder {
    animation: sugarpowder 3s ease-out;
    background: url('./images/bs-sugar-fg.png') 0 0 no-repeat;
    background-size: contain;
    height: 100vh;
    right: 0;
    position: absolute;
    top: 0%;
    width: 75vw;
    z-index: 88;

    @media (min-width: 1024px) {
      top: -20%;
    }
  }

  &_character {
    @keyframes character {
      from {
        opacity: 0;
      };
      to {
        opacity: 1;
      };
    }

    animation: character 3s ease-out 2s forwards;
    background: var(--obs-light-yellow) url('./images/bs-shop-char.png') top center no-repeat;
    background-size: 85% 85%;
    background-position-y: 25px;
    border-radius: 100%;
    height: 50px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all .35s ease-out;
    text-indent: -1000%;
    overflow: hidden;
    width: 50px;
    z-index: 9999;

    @media (min-width: 768px) {
      height: 70px;
      right: 20px;
      top: 20px;
      width: 70px;
    }

    @media (min-width: 1024px) {
      background-position-y: 35px;
      height: 80px;
      right: 25px;
      top: 25px;
      width: 80px;
    }

    &:hover {
      background-position-y: 10px;
      cursor: pointer;
    }
  }

  &_brand {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;

    @keyframes beignetspot {
      0% {        
        opacity: 0;
        top: 50px;
      }
      100% {
        opacity: 1;
        top: 25px;
      }
    }

    @media (min-width: 375px) {
      @keyframes beignetspot {
        0% {        
          opacity: 0;
          top: 70px;
        }
        100% {
          opacity: 1;
          top: 35px;
        }
      }
    }
    
    @media (min-width: 375px)
    and (min-height: 812px) {
      @keyframes beignetspot {
        0% {        
          opacity: 0;
          top: 100px;
        }
        100% {
          opacity: 1;
          top: 50px;
        }
      }
    }
    
    @media (min-width: 400px) {
      @keyframes beignetspot {
        0% {        
          opacity: 0;
          top: 70px;
        }
        100% {
          opacity: 1;
          top: 40px;
        }
      }
    }
    
    @media (min-width: 1024px)
    and (min-height: 1366px) {
      height: 60%;
    }
    
    @media (min-width: 1100px) {
      height: 80%;
    }
    
    @media (min-width: 1440px) {
      height: 100%;
      @keyframes beignetspot {
        0% {        
          opacity: 0;
          top: 70px;
        }
        100% {
          opacity: 1;
          top: 0px;
        }
      }
    }

    @media (min-width: 1900px) {
      height: 80%;
    }

    &__name {
      animation: beignetspot 3s;
      animation-timing-function: ease-out;
      background: url('./images/beignet-spot-logo-v2.svg') top center no-repeat;
      background-size: contain;
      height: 175px;
      left: 50%;
      position: absolute;
      top: 25px;
      transform: translateX(-50%);
      width: 175px;
      z-index: 999;

      @media (min-width: 375px) {
        height: 225px;
        margin: 0 auto;
        top: 35px;
        width: 200px;
      }
      
      @media (min-width: 400px) {
        top: 40px;
        height: 200px;
        width: 300px;
      }
      
      @media (min-width: 375px)
      and (min-height: 812px) {
        height: 250px;
        margin: 0;
        top: 50px;
        width: 300px;
      }
      
      @media (min-width: 768px) {
        height: 350px;
        width: 450px;
      }
      
      @media (min-width: 1024px) {
        height: 270px;
        margin: 0;
        width: 350px;
      }
      
      @media (min-width: 1440px) {
        height: 300px;
        margin: 50px 0 0 0;
        top: 0;
        width: 350px;
      }
      
      @media (min-width: 1600px) {
        height: 300px;
        margin: 50px auto 25px;
        width: 450px;
      }

      @media (min-width: 1900px) {
        height: 420px;
        margin: 0;
        width: 650px;
      }
    }
    
    @keyframes beignet {
      0% {        
        left: 55%;
        opacity: 0;
      }
      100% {
        left: 49%;
        opacity: 1;
      }
    }

    @media (min-width: 1900px) {
      @keyframes beignet {
        0% {        
          left: 55%;
          opacity: 0;
        }
        100% {
          left: 50%;
          opacity: 1;
        }
      }
    }

    &__logo {
      animation: beignet 3s;
      animation-timing-function: ease-in-out;
      background: url('./images/bs-beignet.png') top center no-repeat;
      background-size: contain;
      height: 200px;
      left: 49%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 250px;

      @media (min-width: 375px)
      and (min-height: 812px) {
        height: 210px;
        top: 50%;
        width: 300px;
      }
      
      @media (min-width: 400px) {
        top: 48%;
      }

      @media (min-width: 768px) {
        height: 300px;
        width: 400px;
      }
      
      @media (min-width: 1024px) {
        height: 300px;
        top: 60%;
        width: 300px;
      }
      
      @media (min-width: 1440px) {
        height: 350px;
        top: 60%;
        width: 350px;
      }
      
      @media (min-width: 1600px) {
        height: 350px;
        width: 500px;
      }
      
      @media (min-width: 1900px) {
        left: 50%;
        height: 450px;
        width: 550px;
      }
    }
    
    //Action Button
    @keyframes action {
      0% {        
        bottom: 50px;
        opacity: 0;
      }
      100% {
        bottom: calc(35px + env(safe-area-inset-bottom));
        opacity: 1;
      }
    }

    @media (min-width: 375px) {
      @keyframes action {
        0% {        
          bottom: 100px;
          opacity: 0;
        }
        100% {
          bottom: calc(75px + env(safe-area-inset-bottom));
          opacity: 1;
        }
      }
    }
    
    @media (min-width: 375px)
    and (min-height: 812px) {
      @keyframes action {
        0% {        
          bottom: 100px;
          opacity: 0;
        }
        100% {
          bottom: calc(150px + env(safe-area-inset-bottom));
          opacity: 1;
        }
      }
    }

    @media (min-width: 400px) {
      @keyframes action {
        0% {        
          bottom: 200px;
          opacity: 0;
        }
        100% {
          bottom: calc(165px + env(safe-area-inset-bottom));
          opacity: 1;
        }
      }
    }
    
    @media (min-width: 768px) {
      @keyframes action {
        0% {        
          bottom: 100px;
          opacity: 0;
        }
        100% {
          bottom: 50px;
          opacity: 1;
        }
      }
    }
    
    @media (min-width: 1024px) {
      @keyframes action {
        0% {        
          bottom: 100px;
          opacity: 0;
        }
        100% {
          bottom: 75px;
          opacity: 1;
        }
      }
    }
    
    @media (min-width: 1440px) {
      @keyframes action {
        0% {        
          bottom: 130px;
          opacity: 0;
        }
        100% {
          bottom: 100px;
          opacity: 1;
        }
      }
    }

    &__action {
      @include AzoSansBold;
      animation: action 3s;
      animation-timing-function: ease-out;
      background: var(--obs-yellow);
      border-radius: 50px;
      bottom: calc(35px + env(safe-area-inset-bottom));
      color: #000;
      font-size: 30px;
      font-weight: 600;
      font-weight: 600;
      font-size: 10px;
      left: 50%;
      padding: 25px 35px;
      position: absolute;
      text-decoration: none;
      text-transform: uppercase;
      transform: translateX(-50%);
      max-width: 350px;
      width: 65%;
      z-index: 99999999999;

      @media (min-width: 375px) {
        bottom: calc(75px + env(safe-area-inset-bottom));
      }

      @media (min-width: 375px)
      and (min-height: 812px) {
        bottom: calc(150px + env(safe-area-inset-bottom));
        font-size: 12px;
        margin: 0;
      }

      @media (min-width: 400px) {
        bottom: calc(165px + env(safe-area-inset-bottom));
        margin: 0;
      }

      @media (min-width: 768px) {
        bottom: 50px;
        font-size: 14px;
        margin: 0;
        padding: 30px 70px;
        width: 60%;
      }

      @media (min-width: 1024px) {
        bottom: 75px;
        font-size: 14px;
        margin: 0 0 0;
        padding: 25px 50px;
        width: 35%;
      }
      
      @media (min-width: 1440px) {
        bottom: 100px;
        font-size: 14px;
        margin: 0;
        padding: 25px 75px;
        width: 25%;
      }
      
      @media (min-width: 1600px) {
        font-size: 14px;
        margin: 35px 0 0;
        display: block;
        padding: 25px 15px;
        width: 75%;
      }
    }
  }

  &_opening {
    @include AzoSansUber;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 100%;
    z-index: 0;

    @media (min-width: 375px)
    and (min-height: 812px) {
      top: 50%;
    }
    
    @media (min-width: 400px) {
      top: 48%;
    }
    
    @media (min-width: 768px) {
      top: 59%;
    }
    
    @media (min-width: 1024px) {
      top: 57%;
    }
    
    @media (min-width: 1440px) {
      top: 55%;
    }

    &__date {
      color: #fff;
      display: flex;
      flex-direction: column;
      font-size: 120px;
      height: 50vh;
      left: 50%;
      margin: 0;
      padding: 0;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media (min-width: 375px){
        height: 43vh;
      }
      
      @media (min-width: 375px)
      and (min-height: 812px) {
        font-size: 135px;
        height: 40vh;
      }

      @media (min-width: 400px) {
        font-size: 150px;
        height: 45vh;
      }
      
      @media (min-width: 768px) {
        line-height: 1;
        font-size: 250px;
        height: 50vh;
      }

      @media (min-width: 1024px) {
        height: 23vh;
        flex-direction: row;
        font-size: 170px;
        margin: auto;
      }
      
      @media (min-width: 1024px)
      and (min-height: 1366px) {
        height: 12vh;
        flex-direction: row;
        font-size: 155px;
        margin: auto;
      }
      
      @media (min-width: 1280px) {
        flex-direction: row;
        font-size: 230px;
        height: 25vh;
      }
      
      @media (min-width: 1600px) {
        flex-direction: row;
        font-size: 235px;
      }
      
      @media (min-width: 1900px) {
        flex-direction: row;
        font-size: 300px;
      }

      &___monthdate {
        &:first-child {
          color: var(--obs-dark-orange);
          left: 50%;
          position: absolute;
          transform: translateX(-50%);

          @media (min-width: 1024px) {
            left: 0;
            transform: translateX(0);
          }
          
          @media (min-width: 1024px)
          and (min-height: 1366px) {
            @keyframes fall {
              from {
                left: 100px;
              };
              to {
                left: 50px;
              };
            }

            animation: fall 3s ease-out forwards;
            left: 60px;
            transform: translateX(0);
          }
          
          @media (min-width: 1100px) {
            @keyframes fall {
              from {
                left: 150px;
              };
              to {
                left: 50px;
              };
            }

            animation: fall 3s ease-out forwards;
            left: 60px;
            transform: translateX(0);
          }

          @media (min-width: 1600px) {
            @keyframes fall {
              from {
                left: 100px;
              };
              to {
                left: 60px;
              };
            }

            animation: fall 3s ease-out forwards;
            left: 60px;
            transform: translateX(0);
          }
          
          @media (min-width: 1900px) {
            @keyframes fall {
              from {
                left: 300px;
              };
              to {
                left: 250px;
              };
            }

            animation: fall 3s ease-out forwards;
            left: 250px;
            transform: translateX(0);
          }
        }
        
        &:last-child {
          border: 0;
          bottom: 0;
          color: var(--obs-dark-yellow);
          left: 50%;
          position: absolute;
          transform: translateX(-50%);

          @media (min-width: 1024px) {
            bottom: auto;
            left: auto;
            right: 0;
            transform: translateX(0);
          }
          
          @media (min-width: 1024px)
          and (min-height: 1366px) {
            @keyframes fall2021 {
              from {
                right: 100px;
              };
              to {
                right: 50px;
              };
            }

            animation: fall2021 3s ease-out forwards;
            bottom: auto;
            left: auto;
            right: 60px;
            transform: translateX(0);
          }
          
          @media (min-width: 1100px) {
            @keyframes fall2021 {
              from {
                right: 150px;
              };
              to {
                right: 50px;
              };
            }

            animation: fall2021 3s ease-out forwards;
            bottom: auto;
            left: auto;
            right: 60px;
            transform: translateX(0);
          }
          
          @media (min-width: 1600px) {
            @keyframes fall2021 {
              from {
                right: 100px;
              };
              to {
                right: 60px;
              };
            }

            animation: fall2021 3s ease-out forwards;
            bottom: auto;
            left: auto;
            right: 60px;
            transform: translateX(0);
          }
         
          @media (min-width: 1900px) {
            @keyframes fall2021 {
              from {
                right: 300px;
              };
              to {
                right: 250px;
              };
            }

            animation: fall2021 3s ease-out forwards;
            bottom: auto;
            left: auto;
            right: 250px;
            transform: translateX(0);
          }
        }
      }
    }
  }
}