html, body {
  height: 100vh;
  width: 100vw;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  background: linear-gradient(180deg, var(--obs-yellow) 0%, var(--obs-orange) 100%);
  background-repeat: no-repeat;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --obs-yellow: #fccb16;
  --obs-light-yellow: #ffcf0f;
  --obs-dark-yellow: rgb(255, 154, 10);
  --obs-dark-yellow-70: rgba(255, 154, 10, .90);
  --obs-orange: #ff790c;
  --obs-dark-orange: #fc5605;
}